<template>
    <div class="print-page-break">
        <!-- optional section switch button -->
        <v-container  v-if="section.optional" fluid class="print-block">
            <v-switch
                color="primary"
                v-model="skipSection"
                :label="switchButtonLabel(section)"
                @change="emitSkipSectionChange"
            />
        </v-container>

        <v-container>
            <v-row class="position-sticky white print-block print-margin-top">
                <v-col cols="12" class="print-space">
                    <h2 class="headline print-text-center">
                        Section {{ sectionIndex + 1 }} / {{ sectionsTotal }} : {{ setLanguage(section.title, selectedLang) }}
                    </h2>
                </v-col>
            </v-row>
            <v-row class="print-block">
                <v-col cols="12" class="print-space">
                    <div v-html="setLanguage(section.description, selectedLang)" class="section-description print-text-center"></div>
                </v-col>
            </v-row>
            <v-row v-for="(question, questionIndex) in section.questions" :key="question.id" class="ma-0 pa-0 print-block">
                <v-col cols="12" class="ma-0 pa-0">
                    <!-- the span element creates a headbutt point so when clicking on the link it wont to scroll too far anymore-->
                    <span class="headbutt" :id="!!question && !!question.id ? question.id.toString() : ''"/>

                    <QuestionAnswer
                        class="print-page-break print-space"
                        :object="question"
                        :answer-object="getQuestionAnswer(question.id)"
                        :order="`${sectionIndex + 1}.${questionIndex + 1}`"
                        :questionIndex="questionIndex"
                        :selectedLang="selectedLang"
                        :isSubmit="isSubmit"
                        :assignationIdToOutput="assignationIdToOutput"
                        :checkboxActivatorIndentation="0"
                        @answerChange="emitAnswerChange"
                        @imagesChange="emitImagesChange"
                        :readonly="readonly"
                        :print="print"
                        :section-is-optional="skipSection"
                        :parent-trail="setLanguage(section.title, selectedLang)"
                    />
                </v-col>
            </v-row>

            <!--buttons change section right, change section left and submit-->
            <v-row v-if="showArrows" class="print-block">
                <v-col cols="6" class="my-0 py-0 text-right">
                    <v-btn fab color="primary" @click="emitLeftArrowClick"><v-icon>arrow_back</v-icon></v-btn>
                </v-col>
                <v-col cols="6" class="my-0 py-0">
                    <v-btn fab color="primary" @click="emitRightArrowClick" v-if="!isLastSection"><v-icon>arrow_forward</v-icon></v-btn>
                    <v-btn fab color="success" @click="emitSubmit" v-else><v-icon>cloud_upload</v-icon></v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import QuestionAnswer from '../Answers/QuestionAnswer';
    import shared from '../../shared';

    export default {
        name: "SurveySection.vue",
        props: [
            'section',
            'sectionIndex',
            'selectedLang',
            'getQuestionAnswer',
            'assignationIdToOutput',
            'isSubmit',
            'showArrows',
            'isLastSection',
            'readonly',
            'sectionsTotal',
            'print',
            'skipped'
        ],
        components: { QuestionAnswer },
        mounted() {
            this.setLanguage = shared.setLanguage;
            this.skipSection = this.skipped;
        },
        data: function() {
            return {
                //shared functions from shared.js
                setLanguage: function() {},
                skipSection: false
            }
        },
        methods: {
            //switch button  Optional section label text
            switchButtonLabel(section) {
                return section.optional ? this.setLanguage(section.optionalLabel, this.selectedLang) : '';
            },

            emitAnswerChange(event) {
                this.$emit('answerChange', event);
            },

            emitImagesChange(event) {
                this.$emit('imagesChange', event);
            },

            emitSubmit() {
                this.$emit('submit');
            },

            emitRightArrowClick() {
                this.$emit('rightArrowClick');
            },

            emitLeftArrowClick() {
                this.$emit('leftArrowClick');
            },
            emitSkipSectionChange() {
                this.$emit('skipSectionChange', this.skipSection);
            }
        },
        watch: {
            skipped(newValue) {
                this.skipSection = newValue;
            }
        }
    }
</script>

<style scoped>
    div.section-description p img{
        max-width: 350px;
    }

    .headbutt {
        display: block;
        height: 150px; /*same height as header*/
        margin-top: -150px; /*same height as header*/
        visibility: hidden;
    }

    .position-sticky {
        position: sticky;
        top: 1px;
        left: 0;
        z-index: 1;
        box-shadow: 0 1px 5px #000;
    }

    @media print {
        .print-page-break {
            break-inside: avoid;
        }

        .print-text-center {
            text-align: center;
        }

        .print-space {
            margin: 0;
            padding: 0;
        }

        .print-block {
            display:block;
        }

        .print-margin-top {
            margin-top: 15px;
        }
    }
    @media (max-width: 600px) {
        ::v-deep .section-description img{
            max-width: 350px;
        }
    }
</style>
