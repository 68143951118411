<template>
    <v-card class="main-card">
        <v-card-title class="main-title">
            <span class="txt-blue">D</span><span class="txt-e">E</span><span class="txt-red">V</span>&nbsp;M<span class="txt-green">O</span><span class="txt-d">D</span><span class="txt-black">E</span>
        </v-card-title>
        <v-card-text>
            <v-list class="list-wrapper">
                <v-list-item class="list-item-wrapper" v-for="(lineMessage, index) in devMessages" :key="index">
                    <v-list-item-content class="list-item">
                        <v-list-item-title>- {{lineMessage}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: "DevBuilder",
    props: [
        'devMessages',
    ],
    mounted() {
    },
    methods:{
    },
}
</script>

<style scoped>
    .main-card{
        z-index:1000;
        position:absolute;
        width: 350px;
        min-height: 250px;
        background-color: #818181;
        top:80px;
        left:20px;
    }

    .main-title{
        color:#FFFFFF;
        margin:0;
        padding:0;
        padding-left:18px;
    }

    .list-wrapper{
        background-color: transparent;
        padding: 0;
    }

    .list-item-wrapper{
        height: 25px;
        padding:0;
        margin:0;
    }

    .list-item{
        color: white;
        padding:0;
        height: 25px;
    }

    .txt-blue{color:blue;}
    .txt-red{color:red;}
    .txt-green{color:chartreuse;}
    .txt-black{color:black;}

    .txt-e{margin-bottom: 2px;}
    .txt-d{margin-top: 5px;}

    .d-bug{
        border:2px solid darkred;
    }
</style>
