//import $ from "jquery";

const subDomain = process.env.VUE_APP_SUBDOMAIN != null ? process.env.VUE_APP_SUBDOMAIN : '';

export const ENV = Object.freeze({
    DEV_MODE: false,
    API_URL: 'https://' + subDomain + 'gallup.groupestageline.com/api/v2',
    URL: 'https://' + subDomain + 'gallup.groupestageline.com',
    //APPLICATION_ID: 2,
    ERROR_CODE_CONNECTION_TIMEOUT: 'ECONNABORTED',
    ERROR_TOKEN_EXPIRED: 'Token is Expired',
    ERROR_TOKEN_NOT_FOUND: 'Authorization Token not found',
    AUTHORIZED_USER_TO_STAMP : ['421','212']
});


export default {
    ENV
}
