import Vue from 'vue'
import Vuex from 'vuex'
import $axios from "./api"
import shared from "./shared";
import i18n from "@/plugins/i18n";


Vue.use(Vuex);

export default new Vuex.Store({
    //global state
    state: {
        //global status
        devMessages:[],
        isLoading: false,
        loadingProcessCount: 0,
        loadingMessage: '',
        showLoadingButton: false,
        isErrorState: false,
        errorMessage: '',
        availableLocales: ['en', 'fr'],
        openFromNc: false,
        openFromCreateFun: false,

        //login
        token: localStorage.getItem('access_token') || null,
        loggedUser: {},

        //users for this application
        appUsers: [],

        //users / contacts (regardless of application rights)
        users: [],

        //groups
        groups: [],

        //all questions without children
        questions: [],
        activeQuestion: {
            id: null,
            userId: null,
            title: [
                {
                    code: 'fr',
                    text: ''
                },
            ],
            description: [
                {
                    code: 'fr',
                    text: ''
                }
            ],
            type: '',
            details: {
                choices: [
                    [
                        {
                            code: 'fr',
                            text: ''
                        }
                    ]
                ],
                label: [
                    {
                        code: 'fr',
                        text: ''
                    }
                ],
                number: {
                    unit: '',
                    decimal: false
                },
                scale: {
                    step: 1,
                    min: 0,
                    max: 100,
                },
            },
            options: [],
            optionComments: null,
            optionExplanation: null,
            optionImages: null,
            optionOptional: null,
            optionYesNo:null,
            optionCheckboxActivator: null,
            optionQuantity: null,
            optionYesNoImages: null,
            tags: [],
            tagsIds: [],
            categories: [],
            categoriesIds: [],
            children: []
        },
        defaultQuestion: {
            id: null,
            userId: null,
            title: [
                {
                    code: 'fr',
                    text: ''
                }
            ],
            description: [
                {
                    code: 'fr',
                    text: ''
                }
            ],
            type: '',
            details: {
                choices: [
                    [
                        {
                            code: 'fr',
                            text: ''
                        }
                    ]
                ],
                label: [
                    {
                        code: 'fr',
                        text: ''
                    }
                ],
                number: {
                    unit: '',
                    decimal: false
                },
                scale: {
                    step: 1,
                    min: 0,
                    max: 100,
                },
            },
            options: [],
            optionComments: null,
            optionExplanation: null,
            optionImages: null,
            optionOptional: null,
            optionYesNo:null,
            optionCheckboxActivator: null,
            optionQuantity: null,
            optionYesNoImages: null,
            tags: [],
            tagsIds: [],
            categories: [],
            categoriesIds: [],
            children: []
        },

        //parametres de questions
        questionTypes: [
            {type: "noanswer", text: "Sans réponse"},
            {type: "text", text: "Texte"},
            {type: "shorttext", text: "Texte Court"},
            {type: "single", text: "Choix simple"},
            {type: "multiple", text: "Choix multiple"},
            {type: "date", text: "Date"},
            {type: "time", text: "Heure"},
            {type: "number", text: "Nombre"},
            {type: "scale", text: "Échelle"},
            {type: "compound", text: "Composée"},
            {type: "contact", text: "Contact"},
            {type: "address", text: "Adresse"},
        ],
        questionOptions: [
            {option: "optional", text: "Facultatif"},
            {option: "images", text: "Galerie d'images"},
            {option: "comment", text: "Commentaires facultatif"},
            {option: "explanation", text: "Explication"},
            {option: "yesno", text: "Oui / Non & Détails"},
            {option: "checkbox", text: "Activateur Checkbox"},
            {option: "quantity", text: "Quantité pour réponse"},
            {option: "impact", text: "Code d'impact pour réponse"},
            {option: "yesnoimages", text: "Images pour Oui / Non & Détails"},
        ],

        //tags
        tags: [],
        activeTag: {},
        defaultTag: {
            id: null,
            title: '',
        },

        //categories
        categories: [],
        categoriesTree: [],
        activeCategory: {
            id: null,
            title: '',
            parentId: null,
            children: []
        },
        defaultCategory: {
            id: null,
            title: '',
            parentId: null,
            children: []
        },

        //surveys creation
        surveys: [],
        activeSurvey: {
            surveyTypeId: null,
            title: [
                {
                    code: 'fr',
                    text: ''
                }
            ],
            description: [
                {
                    code: 'fr',
                    text: ''
                }
            ],
            sections: [],
            options: [],
        },
        defaultSurvey: {
            surveyTypeId: null,
            title: [
                {
                    code: 'fr',
                    text: ''
                }
            ],
            description: [
                {
                    code: 'fr',
                    text: ''
                }
            ],
            sections: [],
            options: [],
        },
        activeSection: {
            title: [
                {
                    code: 'fr',
                    text: ''
                }
            ],
            description: [
                {
                    code: 'fr',
                    text: ''
                }
            ],
            order: 0,
            questions: [],
            optional: false,
            optionalLabel: [
                {
                    code: 'fr',
                    text: ''
                }
            ]
        },
        defaultSection: {
            title: [
                {
                    code: 'fr',
                    text: ''
                }
            ],
            description: [
                {
                    code: 'fr',
                    text: ''
                }
            ],
            order: 0,
            questions: [],
            optional: false,
            optionalLabel: [
                {
                    code: 'fr',
                    text: ''
                }
            ]
        },

        //assignations
        assignations: [],
        activeAssignation: null,

        //things for the client report
        resolutions: [],
        resolutionsParts: [],
        clientAssignationInfo: {},

        //stage models
        stageModels: [],

        //stage units
        stageUnits: [],

        //suppliers
        suppliers: [],

        //client suppliers
        clientSuppliers: [],

        //survey types
        surveyTypes: [],
        surveyType: {},
        activeSurveyType: {
            id: null,
            name: '',
            signatureNeeded: false,
            stageModelNeeded: false,
            stageUnitNeeded: false,
            clientNeeded: false,
            projectNeeded: false,
            notifyAssignation: false,
            assignationEmailTitle: [
                {
                    code: 'fr',
                    text: ''
                }
            ],
            assignationEmailTemplate: [
                {
                    code: 'fr',
                    text: ''
                }
            ],
            assignationEmailAdditional: [],
            notifySubmit: false,
            submitEmailTitle: [
                {
                    code: 'fr',
                    text: ''
                }
            ],
            submitEmailTemplate: [
                {
                    code: 'fr',
                    text: ''
                }
            ],
            submitEmailAdditional: [],
            showReportDisclaimer: true,
            reportDisclaimer: [
                {
                    code: 'fr',
                    text: ''
                }
            ],
            reportDisclaimerTitle: [
                {
                    code: 'fr',
                    text: ''
                }
            ],
            daysForExpiration: 0,
            expirationStartTriggerState: 'Closed',
            expirationMessage: [
                {
                    code: 'fr',
                    text: ''
                }
            ],
            expirationMessageTitle: [
                {
                    code: 'fr',
                    text: ''
                }
            ],
        },
        defaultSurveyType: {
            id: null,
            name: '',
            autosaveImages: false,
            signatureNeeded: false,
            stageUnitNeeded: false,
            clientNeeded: false,
            projectNeeded: false,
            notifyAssignation: false,
            assignationEmailTitle: [
                {
                    code: 'fr',
                    text: ''
                }
            ],
            assignationEmailTemplate: [
                {
                    code: 'fr',
                    text: ''
                }
            ],
            assignationEmailAdditional: [],
            notifySubmit: false,
            submitEmailTitle: [
                {
                    code: 'fr',
                    text: ''
                }
            ],
            submitEmailTemplate: [
                {
                    code: 'fr',
                    text: ''
                }
            ],
            submitEmailAdditional: [],
            showReportDisclaimer: true,
            reportDisclaimer: [
                {
                    code: 'fr',
                    text: ''
                }
            ],
            reportDisclaimerTitle: [
                {
                    code: 'fr',
                    text: ''
                }
            ],
            daysForExpiration: 0,
            expirationStartTriggerState: 'Closed',
            expirationMessage: [
                {
                    code: 'fr',
                    text: ''
                }
            ],
            expirationMessageTitle: [
                {
                    code: 'fr',
                    text: ''
                }
            ],

        },

        //NC
        ncDepartments: [],
        ncUnderDepartments: [],
        ncCategories: [],
        ncCauses: [],
        ncCorrectivesActions: [],
        //states for NC of type SAV only
        ncSavStates: [],

        //maintenance dashboard
        ncList: [],
        activeMaintenanceNC: {},
        activeNCGallupInfos: {},
        criticalityMaintenance: [
            { value: 0, text: '0 - Hors service', text_en: 'Out of order', text_fr: 'Hors service', color: "red--text"},
            { value: 1, text: '1 - Critique', text_en: 'Critical', text_fr: 'Critique', color: "red--text" },
            { value: 11, text: '1T - Critique temporaire', text_en: 'Temporary Critical', text_fr: 'Critique Temporaire', color: "red--text" },
            { value: 2, text: '2 - À monitorer', text_en: 'To monitor', text_fr: 'À monitorer', color: "orange--text" },
            { value: 3, text: '3 - Suggéré', text_en: 'Suggested', text_fr: 'Suggéré', color: "yellow--text" },
        ],
        laborTypesMaintenance: [
            { value: 'acier', text: 'Soudure acier', text_en: 'Steel welding', text_fr: 'Soudure acier' },
            { value: 'alu', text: 'Soudure aluminium', text_en: 'Aluminium welding', text_fr: 'Soudure aluminium' },
            { value: 'maint', text: 'Maintenance', text_en: 'Maintenance', text_fr: 'Maintenance' },
            { value: 'hyd', text: 'Hydraulique', text_en: 'Hydraulics', text_fr: 'Hydraulique' },
            { value: 'peint', text: 'Peinture', text_en: 'Paint', text_fr: 'Peinture' },
            { value: 'mec', text: 'Mécanique', text_en: 'Mechanical', text_fr: 'Mécanique' },
            { value: 'autre', text: 'Autre', text_en: 'Other', text_fr: 'Autre' },
        ],
        //states Maintenance
        //NC of type Maintenance states transition
        statesMaintenance: [
            { value: '', text: 'Tous' },
            { value: 'maintenance_new', text: 'Nouveau' },
            { value: 'maintenance_for_revision', text: 'À réviser' },
            { value: 'maintenance_revised', text: 'Révisé par ING' },
            { value: 'maintenance_planned', text: 'Planifié' },
            { value: 'maintenance_conforme', text: 'Conforme' },
            { value: 'maintenance_in_progress', text: 'En cours' },
            { value: 'maintenance_complete', text: 'Tâche complété' },
            { value: 'maintenance_validate', text: 'À valider' },
            { value: 'maintenance_non_conforme', text: 'Non-Conforme' },
            { value: 'maintenance_closed', text: 'Terminé' },
            { value: 'maintenance_deleting', text: 'En supression' },
            { value: 'maintenance_deleted', text: 'Supprimé' },
            { value: 'maintenance_redo', text: 'À Refaire' },
        ],
        //NC SAV criticality
        criticalitySAV: [
            { value: 1, text: 'Comme neuf', text_en: 'As new', text_fr: 'Comme neuf', },
            { value: 2, text: 'Esthétique', text_en: 'Esthetics', text_fr: 'Esthétique' },
            { value: 3, text: 'Non fonctionnel', text_en: 'Not functional', text_fr: 'Non fonctionnel' },
            { value: 4, text: 'Non fonctionnel, empêche certification', text_en: 'Not functional, prevents certification', text_fr: 'Non fonctionnel, empêche certification' },
            { value: 5, text: 'Réside au sol', text_en: 'Ground', text_fr: 'Réside au sol' },
        ],
        partNos: [],
        dispatchCountries: [
            { text: 'Canada', value: 'CA'},
            { text: 'United States', value: 'US'},
        ],
        dispatchProvinces: [
            { value: "AB", text: "Alberta" },
            { value: "BC", text: "British Columbia" },
            { value: "MB", text: "Manitoba" },
            { value: "NB", text: "New Brunswick" },
            { value: "NL", text: "Newfoundland and Labrador" },
            { value: "NS", text: "Nova Scotia" },
            { value: "NT", text: "Northwest Territories" },
            { value: "NU", text: "Nunavut" },
            { value: "ON", text: "Ontario" },
            { value: "PE", text: "Prince Edward Island" },
            { value: "QC", text: "Québec" },
            { value: "SK", text: "Saskatchewan" },
            { value: "YT", text: "Yukon"}
        ],
        dispatchStates: [
            { text: "Alabama", value: "AL" },
            { text: "Alaska", value: "AK" },
            { text: "American Samoa", value: "AS" },
            { text: "Arizona", value: "AZ" },
            { text: "Arkansas", value: "AR" },
            { text: "California", value: "CA" },
            { text: "Colorado", value: "CO" },
            { text: "Connecticut", value: "CT" },
            { text: "Delaware", value: "DE" },
            { text: "District Of Columbia", value: "DC" },
            { text: "Federated States Of Micronesia", value: "FM" },
            { text: "Florida", value: "FL" },
            { text: "Georgia", value: "GA" },
            { text: "Guam", value: "GU" },
            { text: "Hawaii", value: "HI" },
            { text: "Idaho", value: "ID" },
            { text: "Illinois", value: "IL" },
            { text: "Indiana", value: "IN" },
            { text: "Iowa", value: "IA" },
            { text: "Kansas", value: "KS" },
            { text: "Kentucky", value: "KY" },
            { text: "Louisiana", value: "LA" },
            { text: "Maine", value: "ME" },
            { text: "Marshall Islands", value: "MH" },
            { text: "Maryland", value: "MD" },
            { text: "Massachusetts", value: "MA" },
            { text: "Michigan", value: "MI" },
            { text: "Minnesota", value: "MN" },
            { text: "Mississippi", value: "MS" },
            { text: "Missouri", value: "MO" },
            { text: "Montana", value: "MT" },
            { text: "Nebraska", value: "NE" },
            { text: "Nevada", value: "NV" },
            { text: "New Hampshire", value: "NH" },
            { text: "New Jersey", value: "NJ" },
            { text: "New Mexico", value: "NM" },
            { text: "New York", value: "NY" },
            { text: "North Carolina", value: "NC" },
            { text: "North Dakota", value: "ND" },
            { text: "Northern Mariana Islands", value: "MP" },
            { text: "Ohio", value: "OH" },
            { text: "Oklahoma", value: "OK" },
            { text: "Oregon", value: "OR" },
            { text: "Palau", value: "PW" },
            { text: "Pennsylvania", value: "PA" },
            { text: "Puerto Rico", value: "PR" },
            { text: "Rhode Island", value: "RI" },
            { text: "South Carolina", value: "SC" },
            { text: "South Dakota", value: "SD" },
            { text: "Tennessee", value: "TN" },
            { text: "Texas", value: "TX" },
            { text: "Utah", value: "UT" },
            { text: "Vermont", value: "VT" },
            { text: "Virgin Islands", value: "VI" },
            { text: "Virginia", value: "VA" },
            { text: "Washington", value: "WA" },
            { text: "West Virginia", value: "WV" },
            { text: "Wisconsin", value: "WI" },
            { text: "Wyoming", value: "WY" }
        ],
        maintenanceStagesReport: [],
        ncReport: {},
        ncReportsIndex: [],
        availableFun: [],

        formatResolution:[
            {
                "value": "Calendar",
                "text": "Calendrier",
                "sortable": false,
                "icon": "mdi-calendar",
                "status": "",
                "selected": false
            },
            {
                "value": "Parts",
                "text": "Pièces",
                "sortable": false,
                "icon": "mdi-cog",
                "status": "",
                "selected": false
            },
            {
                "value": "Shipping",
                "text": "Expédition",
                "sortable": false,
                "icon": "mdi-truck-delivery",
                "status": "",
                "selected": false
            },
            {
                "value": "Customs",
                "text": "Douanes",
                "sortable": false,
                "icon": "mdi-eye",
                "status": "",
                "selected": false
            },
            {
                "value": "Labor",
                "text": "Main d'oeuvre",
                "sortable": false,
                "icon": "mdi-arm-flex",
                "status": "",
                "selected": false
            },
            {
                "value": "Location",
                "text": "Espace",
                "sortable": false,
                "icon": "mdi-map-marker",
                "status": "",
                "selected": false
            },
            {
                "value": "Tools",
                "text": "Location d'outils",
                "sortable": false,
                "icon": "mdi-tools",
                "status": "",
                "selected": false
            },
            {
                "value": "Dispatch",
                "text": "Répartition",
                "sortable": false,
                "icon": "mdi-bed",
                "status": "",
                "selected": false
            },
            {
                "value": "Resolutions",
                "text": "Résolutions",
                "sortable": false,
                "icon": "mdi-file-certificate",
                "status": "",
                "selected": true
            }
        ],
        formatLabor:[
            {
                "value": "Calendar",
                "text": "Calendrier",
                "sortable": false,
                "icon": "mdi-calendar",
                "status": "",
                "selected": false
            },
            {
                "value": "Parts",
                "text": "Pièces",
                "sortable": false,
                "icon": "mdi-cog",
                "status": "",
                "selected": false
            },
            {
                "value": "Shipping",
                "text": "Expédition",
                "sortable": false,
                "icon": "mdi-truck-delivery",
                "status": "",
                "selected": false
            },
            {
                "value": "Customs",
                "text": "Douanes",
                "sortable": false,
                "icon": "mdi-eye",
                "status": "",
                "selected": false
            },
            {
                "value": "Labor",
                "text": "Main d'oeuvre",
                "sortable": false,
                "icon": "mdi-arm-flex",
                "status": "",
                "selected": true
            },
            {
                "value": "Location",
                "text": "Espace",
                "sortable": false,
                "icon": "mdi-map-marker",
                "status": "",
                "selected": false
            },
            {
                "value": "Tools",
                "text": "Location d'outils",
                "sortable": false,
                "icon": "mdi-tools",
                "status": "",
                "selected": false
            },
            {
                "value": "Dispatch",
                "text": "Répartition",
                "sortable": false,
                "icon": "mdi-bed",
                "status": "",
                "selected": false
            },
            {
                "value": "Resolutions",
                "text": "Résolutions",
                "sortable": false,
                "icon": "mdi-file-certificate",
                "status": "",
                "selected": false
            }
        ],

        //AgentQualityList
        agentQualityList : [],

        //Access Level
        accessLevel:sessionStorage.getItem('accessLevel') || 'all',
    },
    getters: {
        //login
        getAccessToken: (state) => {
            return state.token;
        },
        getUser: (state) => {
            return state.loggedUser;
        },
    },
    //sync ways to change the state
    mutations: {
        //global status
        setIsLoading(state, status) {
            if (status === true) {
                state.loadingProcessCount++;
                state.isLoading = true;
            }
            else if(status === false) {
                if (state.loadingProcessCount > 0) {
                    state.loadingProcessCount--;
                }
                if (state.loadingProcessCount === 0) {
                    state.isLoading = false;
                    state.loadingMessage = '';
                    state.showLoadingButton = false;
                }
            }
        },
        setLoadingMessage(state, message) {
            state.loadingMessage = message;
        },
        setShowLoadingButton(state, show) {
            state.showLoadingButton = show;
        },
        setIsErrorState(state, status) {
            //isLoading is overrided by ErrorState
            state.isLoading = status;
            state.isErrorState = status;
        },
        setErrorMessage(state, message) {
            console.log(message);
            if (message.response && message.response.data) {
                if (message.response.data.errors && message.response.data.errors.message) {
                    state.errorMessage = message.data.errors.message;
                }
                else if (message.response.data.message) {
                    state.errorMessage = message.response.data.message;
                }
            }
            else {
                state.errorMessage = message;
            }
        },
        setLocale(state, locale) {
            i18n.locale = locale;
            localStorage.setItem('locale', i18n.locale);
        },
        initLocale(state) {
            const locale = localStorage.getItem('locale');
            if (locale !== null) {
                i18n.locale = locale;// ERROR
            }
        },

        //login
        updateTokenInfo(state, loginInfo) {
            localStorage.setItem('access_token', loginInfo.access_token);
            state.token = loginInfo.access_token;
        },
        //logout
        deleteTokenInfo(state) {
            localStorage.removeItem('access_token');
            sessionStorage.clear();
            state.token = null;
        },
        setLoggedUser(state, user) {
            state.loggedUser = user;

            state.loggedUser.ncCreate = state.loggedUser.groups.findIndex(group => group.name === 'gallup_nc_create') !== -1;
            state.loggedUser.createQuestions = state.loggedUser.groups.findIndex(group => group.name === 'gallup_questions_creator') !== -1;
            state.loggedUser.createSurveys = state.loggedUser.groups.findIndex(group => group.name === 'gallup_surveys_creator') !== -1;
            state.loggedUser.createTagsCategories = state.loggedUser.groups.findIndex(group => group.name === 'gallup_tags_categories_creator') !== -1;
            state.loggedUser.createAssignations = state.loggedUser.groups.findIndex(group => group.name === 'gallup_assignations_creator') !== -1;
            state.loggedUser.autoAssignation = state.loggedUser.groups.findIndex(group => group.name === 'gallup_auto_assignations') !== -1;
            state.loggedUser.admin = state.loggedUser.groups.findIndex(group => group.name === 'gallup_admin') !== -1;
            state.loggedUser.gallup = state.loggedUser.applications.findIndex(application => application.nom === 'GALLUP') !== -1;
        },

        setEmployeList(state, list){
            state.agentQualityList = list;
        },

        //users
        setUsers(state, users) {
            state.users = users;
            state.users.forEach((user) => {
                user.ncCreate = user.groups.findIndex(group => group.name === 'gallup_nc_create') !== -1;
                user.createQuestions = user.groups.findIndex(group => group.name === 'gallup_questions_creator') !== -1;
                user.createSurveys = user.groups.findIndex(group => group.name === 'gallup_surveys_creator') !== -1;
                user.createTagsCategories = user.groups.findIndex(group => group.name === 'gallup_tags_categories_creator') !== -1;
                user.createAssignations = user.groups.findIndex(group => group.name === 'gallup_assignations_creator') !== -1;
                user.autoAssignation = user.groups.findIndex(group => group.name === 'gallup_auto_assignations') !== -1;
                user.userAgentQuality = user.groups.findIndex(group => group.name === 'gallup_agent_quality') !== -1;
                user.admin = user.groups.findIndex(group => group.name === 'gallup_admin') !== -1;
                user.gallup = user.applications.findIndex(application => application.nom === 'GALLUP') !== -1;
            })
        },
        setUser(state, user) {
            let index = state.users.findIndex(function(element){
                return element.id === user.id;
            });
            user.ncCreate = user.groups.findIndex(group => group.name === 'gallup_nc_create') !== -1;
            user.createQuestions = user.groups.findIndex(group => group.name === 'gallup_questions_creator') !== -1;
            user.createSurveys = user.groups.findIndex(group => group.name === 'gallup_surveys_creator') !== -1;
            user.createTagsCategories = user.groups.findIndex(group => group.name === 'gallup_tags_categories_creator') !== -1;
            user.createAssignations = user.groups.findIndex(group => group.name === 'gallup_assignations_creator') !== -1;
            user.autoAssignation = user.groups.findIndex(group => group.name === 'gallup_auto_assignations') !== -1;
            user.userAgentQuality = user.groups.findIndex(group => group.name === 'gallup_agent_quality') !== -1;
            user.admin = user.groups.findIndex(group => group.name === 'gallup_admin') !== -1;
            user.gallup = user.applications.findIndex(application => application.nom === 'GALLUP') !== -1;
            Vue.set(state.users, index, user);
        },

        //GROUPS
        setGroups(state, groups) {
            state.groups = groups;
        },

        //QUESTIONS

        addQuestion(state, question) {
            state.questions.push(question);
        },

        editQuestion(state, question) {
            let index = state.questions.findIndex(function(element){
                return element.id === question.id
            });
            Vue.set(state.questions, index, question);
        },
        deleteQuestion(state, id) {
            state.questions = state.questions.filter(function(item) {
                return item.id !== id;
            });
        },
        setQuestions: (state, questions) => {
            state.questions = questions;
        },

        //ACTIVE QUESTION
        setActiveQuestion(state, question) {
            if (question === null) {
                state.activeQuestion = JSON.parse(JSON.stringify(state.defaultQuestion));
            } else {
                state.activeQuestion = question;
            }
        },
        setActiveQuestionTitle(state, translatedTitle) {
            state.activeQuestion.title = translatedTitle;
        },
        setActiveQuestionDescription(state, translatedDescription) {
            state.activeQuestion.description = translatedDescription;
        },
        setActiveQuestionDetails(state, details) {
            state.activeQuestion.details = details;
        },
        setActiveQuestionExplanation(state, explanation) {
            state.activeQuestion.optionExplanation.value = explanation;
        },
        setActiveQuestionChildren(state, children) {
            state.activeQuestion.children = children;
        },

        //TAGS
        //sets list of all tags
        setTags(state, tags) {
            state.tags = tags;
        },
        //sets the current tag
        setActiveTag(state, id) {
            if(!!id) {
                state.activeTag = state.tags.find(t => t.id === id);
            }
            else {
                state.activeTag = JSON.parse(JSON.stringify(state.defaultTag));
            }
        },
        //adds tag to list when storing a new one
        addTag(state, tag) {
            state.tags.push(tag);
        },
        //modifies a tag in the list
        modifyTag(state, tag) {
            const tags = [...state.tags];
            const index = state.tags.findIndex(t => t.id === tag.id);
            tags[index] = tag;
            state.tags = tags;
        },
        //removes tag of lists after deleting one
        removeTag(state, tag) {
            state.tags = state.tags.filter(t => t.id !== tag.id);
        },

        //CATEGORIES
        setCategories(state, data) {
            state.categories = data.categories;
            state.categoriesTree = data.categoriesTree;
        },
        setActiveCategory(state, id) {
            if (id === null) {
                state.activeCategory = JSON.parse(JSON.stringify(state.defaultCategory));
            } else {
                let category = state.categories.find(category => category.id === id);
                state.activeCategory = JSON.parse(JSON.stringify(!!category ? category : state.defaultCategory));
            }
        },

        //SURVEYS
        setSurveys(state, surveys) {
            state.surveys = surveys;
        },
        addSurvey(state, survey) {
            state.surveys.push(survey);
        },
        editSurvey(state, survey) {
            let index = state.surveys.findIndex(function(element){
                return element.id === survey.id
            });
            Vue.set(state.surveys, index, survey);
        },
        deleteSurvey(state, id) {
            state.surveys = state.surveys.filter(function(item) {
                return item.id !== id;
            });
        },

        //ACTIVE SURVEY
        setActiveSurvey(state, surveyId) {
            if (!!surveyId) {
                state.activeSurvey = JSON.parse(JSON.stringify(state.surveys.find(survey => survey.id === surveyId)));
            } else {
                state.activeSurvey = JSON.parse(JSON.stringify(state.defaultSurvey));
                state.activeSection = JSON.parse(JSON.stringify(state.defaultSection));
            }
        },
        commitActiveSurvey(state, surveyToCommit) {
            if (!!surveyToCommit) {
                state.activeSurvey = JSON.parse(JSON.stringify(surveyToCommit));
            }
            let activeSurveyIndex = state.surveys.findIndex(survey => survey.id === state.activeSurvey.id);
            Vue.set(state.surveys, activeSurveyIndex, state.activeSurvey);
        },
        setActiveSurveyTitle(state, title) {
            state.activeSurvey.title = title;
        },
        setActiveSurveyDescription(state, description) {
            state.activeSurvey.description = description;
        },

        addSectionActiveSurvey(state) {
            let newSection = JSON.parse(JSON.stringify(state.defaultSection));
            newSection.order = state.activeSurvey.sections.length + 1;
            state.activeSurvey.sections.push(newSection);
        },
        removeSectionActiveSurvey(state, index) {
            if (index < state.activeSurvey.sections.length && index >= 0) {
                state.activeSurvey.sections.splice(index, 1);
            }
        },
        addAssignationActiveSurvey(state, assignation) {
            if (!state.activeSurvey.assignations) {
                state.activeSurvey.assignations = [];
            }
            state.activeSurvey.assignations.push(assignation);
            let activeSurveyIndex = state.surveys.findIndex(survey => survey.id === state.activeSurvey.id);
            Vue.set(state.surveys, activeSurveyIndex, state.activeSurvey);
        },
        editAssignationActiveSurvey(state, assignation) {
            let assignationIndex = state.activeSurvey.assignations.findIndex(ass => ass.id === assignation.id);
            Vue.set(state.activeSurvey.assignations, assignationIndex, assignation);
            let activeSurveyIndex = state.surveys.findIndex(survey => survey.id === state.activeSurvey.id);
            Vue.set(state.surveys, activeSurveyIndex, state.activeSurvey);
        },
        //sets view for IndexQuestionnaire.vue after delete
        deleteAssignationActiveSurvey(state, assignationId) {
            state.activeSurvey.assignations = state.activeSurvey.assignations.filter(assignation => assignation.id !== assignationId);
            let activeSurveyIndex = state.surveys.findIndex(survey => survey.id === state.activeSurvey.id);
            Vue.set(state.surveys, activeSurveyIndex, state.activeSurvey);
        },
        //modifies state of an assignation of an active survey when sending reminder
        modifyAssignationStateActiveSurvey(state, assignation) {
            const activeSurveyAssignations = [...state.activeSurvey.assignations];
            const index = state.activeSurvey.assignations.findIndex(a => a.id === assignation.id);

            activeSurveyAssignations[index].state = assignation.state;
            state.activeSurvey.assignations = activeSurveyAssignations;
        },

        //ACTIVE SECTION
        setActiveSection(state, sectionIndex) {
            if (sectionIndex != null  && sectionIndex !== -1 && state.activeSurvey.sections.length > sectionIndex && state.activeSurvey.sections[sectionIndex] != null) {
                state.activeSection = JSON.parse(JSON.stringify(state.activeSurvey.sections[sectionIndex]));
            } else {
                state.activeSection = JSON.parse(JSON.stringify(state.defaultSection));
            }
        },
        commitActiveSection(state, sectionToCommit) {
            if (typeof sectionToCommit === 'number' && sectionToCommit !== -1) {
                Vue.set(state.activeSurvey.sections, sectionToCommit, state.activeSection);
            } else if (typeof sectionToCommit === 'object') {
                state.activeSection = JSON.parse(JSON.stringify(sectionToCommit));
                let activeSectionIndex = state.activeSurvey.sections.findIndex(section => section.id === state.activeSection.id);
                Vue.set(state.activeSurvey.sections, activeSectionIndex, state.activeSection);
            }
        },
        setActiveSectionTitle(state, title) {
            state.activeSection.title = title;
        },
        setActiveSectionDescription(state, description) {
            state.activeSection.description = description;
        },
        setActiveSectionOrder(state, order) {

        },
        //set optionally optional
        setActiveSectionOptional(state, optionalState) {
            //if option is not selected, we set the label to an empty string
            if(!state.activeSection.optional && state.activeSection.optionalLabel !== null) {
                state.activeSection.optionalLabel = [ { code: 'fr', text: '' } ];
            }
            state.activeSection.optional = optionalState;
        },
        //set optionally optional label
        setActiveSectionOptionalLabel(state, label) {
            state.activeSection.optionalLabel = label;
        },

        moveActiveSectionUp(state, sectionIndex) {
            if (sectionIndex < state.activeSurvey.sections.length && sectionIndex > 0) {
                state.activeSurvey.sections[sectionIndex].order--;
                state.activeSurvey.sections[sectionIndex - 1].order++;
                state.activeSurvey.sections.sort((a,b) => {return a.order - b.order});
            }
        },
        moveActiveSectionDown(state, sectionIndex) {
            if (sectionIndex < state.activeSurvey.sections.length - 1 && sectionIndex >= 0) {
                state.activeSurvey.sections[sectionIndex].order++;
                state.activeSurvey.sections[sectionIndex + 1].order--;
                state.activeSurvey.sections.sort((a,b) => {return a.order-b.order});
            }
        },
        //adds selected questions to active section
        addQuestionsToActiveSection(state, questions) {
            //gets all questions that are already added to the section
            let uploadedQuestions = [];
            state.activeSection.questions.forEach(activeSectionQuestion => {
               uploadedQuestions.push(activeSectionQuestion.question);
            });

            //adds question to section if the question is not added yet
            //creating a question wrapper for each question containing the question itself and other properties
            //pushing the new question wrapper (containing the question itself and other properties) in the active section questions array
            //this is why we need to access a question by doing question.question
            questions.forEach(question => {
                if(!uploadedQuestions.includes(question)) {
                    state.activeSection.questions.push({
                        questionId: question.id,
                        sectionId: state.activeSection.id,
                        order: state.activeSection.questions.length,
                        question: question
                    })
                }
            });
        },
        //deletes question from active section
        deleteQuestionFromActiveSection(state, questionId) {
            //deleting
            state.activeSection.questions = state.activeSection.questions.filter(activeSectionQuestion => activeSectionQuestion.questionId !== questionId);

            //reordering after deleting
            state.activeSection.questions.forEach((activeSectionQuestion, index) => activeSectionQuestion.order = index);
        },
        moveQuestionUp(state, questionIndex) {
            if (questionIndex < state.activeSection.questions.length && questionIndex > 0) {
                state.activeSection.questions[questionIndex].order--;
                state.activeSection.questions[questionIndex - 1].order++;
                state.activeSection.questions.sort((a,b) => {return a.order - b.order});
            }
        },
        moveQuestionDown(state, questionIndex) {
            if (questionIndex < state.activeSection.questions.length-1 && questionIndex >= 0) {
                state.activeSection.questions[questionIndex].order++;
                state.activeSection.questions[questionIndex + 1].order--;
                state.activeSection.questions.sort((a,b) => {return a.order-b.order});
            }
        },

        //ASSIGNATIONS
        setAllAssignations(state, assignations) {
            state.assignations = assignations;
        },
        setActiveAssignation(state, assignation) {
            state.activeAssignation = assignation;
        },
        //sets view for IndexAssignations.vue after delete
        removeAssignation(state, assignationId) {
            state.assignations = state.assignations.filter(a => a.id !== assignationId);
        },
        //modifies assignation state when reminder is sent
        modifyAssignationState(state, assignation) {
            const assignations = [...state.assignations];
            const index = state.assignations.findIndex(a => a.id === assignation.id);

            assignations[index].state = assignation.state;
            state.assignations = assignations;
        },

        //CLIENT REPORT
        setResolutions(state, resolutions) {
            state.resolutions = resolutions;
            state.resolutions.sort((a, b) => {
                const impactA = a.impact !== null && a.impact !== undefined
                    ? String(a.impact)
                    : "";
                const impactB = b.impact !== null && b.impact !== undefined
                    ? String(b.impact)
                    : "";
                return impactA.localeCompare(impactB);
            });
        },
        setResolutionsParts(state, resolutionsParts) {
            state.resolutionsParts = resolutionsParts;
        },
        setClientAssignationInfo(state, clientAssignationInfo) {
            if(clientAssignationInfo !== undefined && clientAssignationInfo !== null){
                console.log(JSON.stringify(clientAssignationInfo));
            }
            state.clientAssignationInfo = clientAssignationInfo;
        },

        //STAGE MODELS
        setAllStageModels(state, stageModels) {
            state.stageModels = stageModels;
        },

        //STAGE UNITS
        setAllStageUnits(state, stageUnits) {
            state.stageUnits = stageUnits;
        },

        //SUPPLIERS
        setSuppliers(state, suppliers) {
            state.suppliers = suppliers;
        },

        //CLIENT SUPPLIERS
        setClientSuppliers(state, clientSuppliers) {
            state.clientSuppliers = clientSuppliers;
        },

        //SURVEY TYPES
        setSurveyTypes(state, surveyTypes) {
            state.surveyTypes = surveyTypes;
        },
        setSurveyType(state, surveyType) {
            state.surveyType = surveyType;
        },
        setActiveSurveyType(state, surveyType) {
            if (!!surveyType && !!surveyType.id) {
                state.activeSurveyType = JSON.parse(JSON.stringify(state.surveyTypes.find(type => type.id === surveyType.id)));
            } else {
                state.activeSurveyType = JSON.parse(JSON.stringify(state.defaultSurveyType));
            }
        },
        commitActiveSurveyType(state, typeToCommit) {
            if (!!typeToCommit) {
                state.activeSurveyType = JSON.parse(JSON.stringify(typeToCommit));
            }
            let activeTypeIndex = state.surveyTypes.findIndex(type => type.id === state.activeSurveyType.id);
            Vue.set(state.surveyTypes, activeTypeIndex, state.activeSurveyType);
        },
        addSurveyType(state, type) {
            state.surveyTypes.push(type);
        },
        deleteSurveyType(state, type) {
            state.surveyTypes = state.surveyTypes.filter(surveyType => surveyType.id !== type.id);
        },

        //NC
        setNcDepartments(state, ncDepartments) {
            state.ncDepartments = ncDepartments.filter(d => !d.parentId);
        },
        setNcUnderDepartments(state, ncDepartments) {
            state.ncUnderDepartments = ncDepartments.filter(d => !!d.parentId);
        },
        setNcCategories(state, ncCategories) {
            state.ncCategories = ncCategories;
        },
        setNcCauses(state, ncCauses) {
            state.ncCauses = ncCauses;
        },
        //sets states list for each type of NC (currently only setting states list for NC of type SAV)
        setNcStates(state, ncStates) {
            state.ncSavStates = ncStates.filter(s => s.name.startsWith('sav_'));
        },
        //sets correctives actions (the list is fetched by NC type)
        setNcCorrectivesActions(state, ncCorrectivesActions) {
            state.ncCorrectivesActions = ncCorrectivesActions;
        },

        //maintenance dashboard
        setNcList(state, ncList) {
            state.ncList = ncList;
        },
        removeFromNcList(state, nc ) {
            state.ncList = state.ncList.filter(n => n.id !== nc.id);
        },
        setActiveMaintenanceNC(state, nc) {
            state.activeMaintenanceNC = nc;
            if (state.activeMaintenanceNC.maintenanceProject && state.activeMaintenanceNC.maintenanceProject.dispatchRequest == null) {
                state.activeMaintenanceNC.maintenanceProject.dispatchRequest = {}
            }

        },
        setActiveNCGallupInfos(state, info) {
            state.activeNCGallupInfos = info;
        },
        commitActiveMaintenanceNC(state) {
            let activeNCIndex = state.ncList.findIndex(nc => nc.id === state.activeMaintenanceNC.id);
            if (activeNCIndex === -1) {
                state.ncList.unshift(state.activeMaintenanceNC);
            }
            else {
                let nc = {
                    id: state.activeMaintenanceNC.id,
                    numeroNc: state.activeMaintenanceNC.numeroNc,
                    stageUnit: state.activeMaintenanceNC.stageUnit,
                    stageModel: state.activeMaintenanceNC.stageModel,
                    gravite: state.activeMaintenanceNC.gravite,
                    etat: state.activeMaintenanceNC.etat,
                    etatMachine: state.activeMaintenanceNC.etatMachine,
                    titre: state.activeMaintenanceNC.titre,
                    createdAt: state.activeMaintenanceNC.createdAt,
                    calendarEnd: state.activeMaintenanceNC.maintenanceProject ? state.activeMaintenanceNC.maintenanceProject.calendarEnd : null,
                    taskCalendarStatus: state.activeMaintenanceNC.maintenanceProject ? state.activeMaintenanceNC.maintenanceProject.taskCalendarStatus : null,
                    taskPartsStatus: state.activeMaintenanceNC.maintenanceProject ? state.activeMaintenanceNC.maintenanceProject.taskPartsStatus : null,
                    taskShippingStatus: state.activeMaintenanceNC.maintenanceProject ? state.activeMaintenanceNC.maintenanceProject.taskShippingStatus : null,
                    taskCustomsStatus: state.activeMaintenanceNC.maintenanceProject ? state.activeMaintenanceNC.maintenanceProject.taskCustomsStatus : null,
                    taskLaborStatus: state.activeMaintenanceNC.maintenanceProject ? state.activeMaintenanceNC.maintenanceProject.taskLaborStatus : null,
                    taskLocationStatus: state.activeMaintenanceNC.maintenanceProject ? state.activeMaintenanceNC.maintenanceProject.taskLocationStatus : null,
                    taskToolsStatus: state.activeMaintenanceNC.maintenanceProject ? state.activeMaintenanceNC.maintenanceProject.taskToolsStatus : null,
                    taskDispatchStatus: state.activeMaintenanceNC.maintenanceProject ? state.activeMaintenanceNC.maintenanceProject.taskDispatchStatus : null,
                    taskResolutionsStatus: state.activeMaintenanceNC.maintenanceProject ? state.activeMaintenanceNC.maintenanceProject.taskResolutionsStatus : null,
                    imagesFolder: state.activeMaintenanceNC.imagesFolder
                }
                Vue.set(state.ncList, activeNCIndex, nc);
            }
        },
        setMaintenanceProjectTaskStatus(state, {taskName, status}) {
            if (state.activeMaintenanceNC.maintenanceProject != null) {
                state.activeMaintenanceNC.maintenanceProject[`task${taskName}Status`] = status;
            }
        },
        addNewPartToMaintenanceProject(state) {
            if (state.activeMaintenanceNC.maintenanceProject != null) {
                state.activeMaintenanceNC.maintenanceProject.parts.push({
                    partNumber: '',
                    description: '',
                    codeHs: '',
                    type: '',
                    da: '',
                    daDate: null,
                    dm: '',
                    dmDate: null,
                    dsi: '',
                    dsiDate: null,
                    quantity: 0.0,
                    price: 0.0,
                });
            }
        },
        setMaintenanceProjectPart(state, {part, index}) {
            Vue.set(state.activeMaintenanceNC.maintenanceProject.parts, index, part);
        },
        removeMaintenanceProjectPart(state, index) {
            Vue.delete(state.activeMaintenanceNC.maintenanceProject.parts, index);
        },
        addNewShippingToMaintenanceProject(state) {
            if (state.activeMaintenanceNC.maintenanceProject != null) {
                state.activeMaintenanceNC.maintenanceProject.shipping.push({
                    destination: '',
                    contact: '',
                    contactPhone: '',
                    contactEmail: '',
                    contentDescription: '',
                    value: 0.0,
                    weight: '',
                    dimensionsWidth: '',
                    dimensionsLength: '',
                    dimensionsHeight: '',
                    trackingNumber: '',
                    company: '',
                    type: '',
                    internalTransport: false,
                    transporterName: '',
                    transporterVehicle: '',
                    umscaSent: false,
                    proformaSent: false
                });
            }
        },
        setMaintenanceProjectShipping(state, {shipping, index}) {
            Vue.set(state.activeMaintenanceNC.maintenanceProject.shipping, index, shipping);
        },
        removeMaintenanceProjectShipping(state, index) {
            Vue.delete(state.activeMaintenanceNC.maintenanceProject.shipping, index);
        },

        addNewLaborToMaintenanceProject(state) {
            if (state.activeMaintenanceNC.maintenanceProject != null) {
                const order= state.activeMaintenanceNC.maintenanceProject.labor.length;
                state.activeMaintenanceNC.maintenanceProject.labor.push({
                    quantity: 0.0,
                    realQuantity: 0.0,
                    description: '',
                    workType: '',
                    name: '',
                    confirmed: false,
                    startDate: !!state.activeMaintenanceNC.maintenanceProject.calendarStart ? state.activeMaintenanceNC.maintenanceProject.calendarStart : '',
                    endDate: !!state.activeMaintenanceNC.maintenanceProject.calendarEnd ? state.activeMaintenanceNC.maintenanceProject.calendarEnd : '',
                    order: order
                });
            }
        },
        setMaintenanceProjectLabor(state, {labor, index}) {
            Vue.set(state.activeMaintenanceNC.maintenanceProject.labor, index, labor);
        },
        changeMaintenanceProjectLaborOrder(state, {change, index}) {
            if (change > 0 && state.activeMaintenanceNC.maintenanceProject.labor.length - 1 > index) {
                state.activeMaintenanceNC.maintenanceProject.labor[index].order++;
                state.activeMaintenanceNC.maintenanceProject.labor[index + 1].order--;
            }
            else if (change < 0 && index > 0) {
                state.activeMaintenanceNC.maintenanceProject.labor[index].order--;
                state.activeMaintenanceNC.maintenanceProject.labor[index - 1].order++;
            }
            state.activeMaintenanceNC.maintenanceProject.labor.sort((a,b) => { return a.order - b.order })
        },
        removeMaintenanceProjectLabor(state, index) {
            Vue.delete(state.activeMaintenanceNC.maintenanceProject.labor, index);
        },

        addNewToolToMaintenanceProject(state) {
            if (state.activeMaintenanceNC.maintenanceProject != null) {
                state.activeMaintenanceNC.maintenanceProject.tools.push({
                    quantity: 0,
                    description: '',
                    startDate: '',
                    endDate: '',
                    company: '',
                    invoiceNumber: '',
                    confirmed: false
                });
            }
        },
        setMaintenanceProjectTool(state, {tool, index}) {
            Vue.set(state.activeMaintenanceNC.maintenanceProject.tools, index, tool);
        },
        removeMaintenanceProjectTool(state, index) {
            Vue.delete(state.activeMaintenanceNC.maintenanceProject.tools, index);
        },

        setMaintenanceProjectResolution(state, {resolution, index}) {
            Vue.set(state.activeMaintenanceNC.maintenanceProject.resolutions, index, resolution);
        },
        removeMaintenanceProjectResolution(state, index) {
            Vue.delete(state.activeMaintenanceNC.maintenanceProject.resolutions, index);
        },

        setMaintenanceStagesReport(state, stages) {
            state.maintenanceStagesReport = stages;
        },
        setDataPartNos(state, data){
            state.partNos = data.partNos;
        },
        setNcReport(state, data) {
            state.ncReport = data.report;
        },
        setNcReportsIndex(state, data) {
            state.ncReportsIndex = data.reports;
            state.availableFun = data.availableFun;
            state.ncReportsIndex.forEach((report) => {
                report.createdAt = report.createdAt.substring(0, 10);
                report.ncNumbersString = Array.isArray(report.ncNumbersArray) ? report.ncNumbersArray.join(', ') : '';
                report.ncStateArray = Array.isArray(report.ncStateArray) ? report.ncStateArray : ['N/A', 0];
            })
        },
        deleteReportFromIndex(state, report) {
            state.ncReportsIndex = state.ncReportsIndex.filter(r => r.id !== report.id);
        },
        updateReportInIndex(state, report) {
            for (let i = 0; i < state.ncReportsIndex.length; i++) {
                if (state.ncReportsIndex[i].id === report.id) {
                    state.ncReportsIndex[i] = report;
                    state.ncReportsIndex[i].createdAt = report.createdAt.substring(0, 10);
                    state.ncReportsIndex[i].ncNumbersString = Array.isArray(report.ncNumbersArray) ? report.ncNumbersArray.join(', ') : '';
                }
            }
        },

        //generate auto resolutions *KB*
        setOpenFromNc(state, value){
            state.openFromNc = value;
        },
        setAddResolution(state){
            const item = {
                maintenanceProjectId: state.activeMaintenanceNC.maintenanceProjectId,
                imageUrls: state.activeMaintenanceNC.imagesFolder.map(i => i.src),
                component: shared.setLanguage(state.activeMaintenanceNC.answer.sectionQuestion.question.title, state.activeNCGallupInfos.defaultLanguage),
                precision: '',
                resolutionRequirement: '',
                impact: null,
                quantity: null,
                measureUnit: '',
                partNumber: null,
                partUnitCost: null
            };
            state.activeMaintenanceNC.maintenanceProject.resolutions.push(item);
        },
        setGenerateResolutions(state){
            if (state.activeMaintenanceNC.answer?.sectionQuestion.question.type === 'multiple') {
                state.activeMaintenanceNC.answer.sectionQuestion.question.details.choices.forEach((choice, index) => {
                    if (state.activeMaintenanceNC.answer.value.includes(index)) {
                        const item = {
                            maintenanceProjectId: state.activeMaintenanceNC.maintenanceProjectId,
                            imageUrls: state.activeMaintenanceNC.imagesFolder.map(i => i.src),
                            component: shared.setLanguage(state.activeMaintenanceNC.answer.sectionQuestion.question.title, state.activeNCGallupInfos.defaultLanguage),
                            precision: shared.setLanguage(choice, this.defaultLang).replace(/<[^>]*>/g, ''),
                            resolutionRequirement: '',
                            impact: null,
                            quantity: null,
                            measureUnit: '',
                            partNumber: null,
                            partUnitCost: null
                        };
                        if (state.activeMaintenanceNC.answer.optionsValue.impactCode != null) {
                            item.impact = state.activeMaintenanceNC.answer.optionsValue.impactCode[index];
                        }
                        if (state.activeMaintenanceNC.answer.optionsValue.quantity != null) {
                            item.quantity = state.activeMaintenanceNC.answer.optionsValue.quantity[index];
                        }
                        state.activeMaintenanceNC.maintenanceProject.resolutions.push(item);
                    }
                });
            }
            else {
                const item = {
                    maintenanceProjectId: state.activeMaintenanceNC.maintenanceProjectId,
                    imageUrls: state.activeMaintenanceNC.imagesFolder.map(i => i.src),
                    component: shared.setLanguage(state.activeMaintenanceNC.answer.sectionQuestion.question.title, state.activeNCGallupInfos.defaultLanguage),
                    precision: shared.setLanguage(state.activeMaintenanceNC.answer.sectionQuestion.question.description, state.activeNCGallupInfos.defaultLanguage).replace(/<[^>]*>/g, ''),
                    resolutionRequirement: '',
                    impact: null,
                    quantity: null,
                    measureUnit: '',
                    partNumber: null,
                    partUnitCost: null
                };
                if (state.activeMaintenanceNC.answer.optionsValue.impactCode != null) {
                    item.impact = state.activeMaintenanceNC.answer.optionsValue.impactCode;
                }
                if (state.activeMaintenanceNC.answer.optionsValue.quantity != null) {
                    item.quantity = state.activeMaintenanceNC.answer.optionsValue.quantity;
                }
                state.activeMaintenanceNC.maintenanceProject.resolutions.push(item);
            }
        },
        setOpenFromCreateFun(state, value){
            state.openFromCreateFun = value;
        },
    },
    //async actions (API calls)
    actions: {
        //Generate auto labors
        async saveProjectAndAnswerformatLabor({state , dispatch}){
            if (state.activeMaintenanceNC.id == null) {
                await dispatch('saveMaintenanceProject', state.activeMaintenanceNC);
                await dispatch('saveMaintenanceProjectSelectedTasks', {nonConformiteId:state.activeMaintenanceNC.id, tasks: state.formatLabor});
            }
            else {
                await dispatch('saveMaintenanceProjectSelectedTasks', {nonConformiteId:state.activeMaintenanceNC.id, tasks: state.formatLabor});
            }
        },

        async autoGenerateLabors({state , dispatch, commit}) {
            if(state.openFromCreateFun === true){
                await dispatch('saveProjectAndAnswerformatLabor');
                commit('addNewLaborToMaintenanceProject');
                commit('setOpenFromCreateFun', false);
            }
        },

        //Generate auto resolutions
        async saveProjectAndAnswerformatResolution({state , dispatch}){
            if (state.activeMaintenanceNC.id == null) {
                await dispatch('saveMaintenanceProject', state.activeMaintenanceNC);
                let nc = _.cloneDeep(state.activeMaintenanceNC);
                state.activeAssignation.answers.forEach(function(answer) {
                    if (answer.id === nc.questionAnswerId) {
                        answer.nonConformite = nc;
                    }
                });
                await dispatch('saveMaintenanceProjectSelectedTasks', {nonConformiteId:state.activeMaintenanceNC.id, tasks: state.formatResolution});
            }
            else {
                await dispatch('saveMaintenanceProjectSelectedTasks', {nonConformiteId:state.activeMaintenanceNC.id, tasks: state.formatResolution});
            }
        },

        async autoGenerateResolutions({state , dispatch, commit}) {
            if(state.activeMaintenanceNC.showInSav && state.openFromNc === false) {
                await dispatch('saveProjectAndAnswerformatResolution');

                if (state.activeMaintenanceNC.imagesFolder.length > 0) {
                    commit('setGenerateResolutions');
                } else {
                    commit('setAddResolution');
                }
            }
        },

        //login
        async login({ commit, dispatch }, loginInfo) {
            commit('setLoadingMessage', 'Loading...');
            commit('setIsLoading', true);
            try {
                const response = await $axios.post('/auth/login', loginInfo);

                //Access logic for rooter.js
                //Restrictions on access to sections of the app for users depend on their type
                if(response.data.user.shopUser === 0){
                    sessionStorage.setItem('accessLevel', 'shop'); //Shop Users
                }
                else{
                    sessionStorage.setItem('accessLevel', 'all'); //All Users
                }

                commit('updateTokenInfo', response.data);
                await dispatch('fetchUser');
                commit('setIsLoading', false);
            } catch (error) {
                if (error.response.data.errors && error.response.data.errors.message) {
                    commit('setErrorMessage', error.response.data.errors.message);
                }
                else {
                    commit('setErrorMessage', error);
                }

                commit('setIsErrorState', true);
            }
        },

        //user
        async fetchUser({ commit }) {
           try {
               const response = await $axios.get(`/auth/user`);
               commit('setLoggedUser', response.data.user);
           } catch(error) {
               //do nothing (we do nothing so it wont show the 401 error in the console if user access report our survey without being logged)
           }
        },

        //Employe dept list from syteline
        async fetchEmployeDeptList({ commit },  groupName) {
            try {
                const response = await $axios.get(`users/group/${groupName}`);
                commit('setEmployeList', response.data.specificUsers);
            } catch(error) {
                //do nothing (we do nothing so it wont show the 401 error in the console if user access report our survey without being logged)
            }
        },

        //users / contacts / groups
        async fetchUsers({ commit }) {
            const response = await $axios.get('users/all');
            commit('setUsers', response.data.users);
        },
        async fetchGroups({ commit }) {
            const response = await $axios.get('users/groups/GALLUP');
            commit('setGroups', response.data.groups);
        },
        async addUserToGroup({ commit }, { user, group }) {
            const response = await $axios.post(`users/${user.id}/groups/${group.id}`);
            commit('setUser', response.data.user);
        },
        async removeUserFromGroup({ commit }, { user, group }) {
            const response = await $axios.delete(`users/${user.id}/groups/${group.id}`);
            commit('setUser', response.data.user);
        },
        async addApplicationToUser({ commit }, { user }) {
            const response = await $axios.post(`users/application/GALLUP/addUser/${user.id}`);
            commit('setUser', response.data.user);
        },
        async removeApplicationFromUser({ commit }, { user }) {
            const response = await $axios.delete(`users/application/GALLUP/deleteUser/${user.id}`);
            commit('setUser', response.data.user);
        },

        //QUESTIONS
        async fetchQuestions({ commit }) {
            commit('setLoadingMessage', 'Loading...');
            commit('setIsLoading', true);
            try {
                const { data } = await $axios.get('questions/');
                commit('setQuestions', data.questions);
                commit('setIsLoading', false);
            }
            catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },
        async fetchFullQuestion({commit}, id) {
            commit('setLoadingMessage', 'Loading...');
            commit('setIsLoading', true);
            try {
                const response = await $axios.get(`questions/${id}`);
                commit('setActiveQuestion', response.data.question);
                commit('setIsLoading', false);
            }
            catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },
        async storeQuestion({ commit }, question) {
            commit('setLoadingMessage', 'Saving...');
            commit('setIsLoading', true);
            try {
                const response = await $axios.post('questions', question);
                commit('addQuestion', response.data.question);
                commit('setIsLoading', false);
            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },
        async editQuestion({ commit }, question) {
            commit('setLoadingMessage', 'Saving...');
            commit('setIsLoading', true);
            try {
                const response = await $axios.put(`questions/${question.id}`, question);
                commit('editQuestion', response.data.question);
                commit('setIsLoading', false);
            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }

        },
        async deleteQuestion({ commit }, question) {
            commit('setLoadingMessage', 'Deleting...');
            commit('setIsLoading', true);
            try {
                const response = await $axios.delete(`questions/${question.id}`);
                commit('deleteQuestion', response.data);
                commit('setIsLoading', false);
            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }

        },
        async fetchQuestionClone({ commit }, questionId){
            commit('setLoadingMessage', 'Loading...');
            commit('setIsLoading', true);
            try {
                const { data } = await $axios.get(`questions/${questionId}`);
                delete data.question.id;

                //if question has children, we recursively delete the id of all children
                if(!!data.question.children && data.question.children.length > 0) {
                    shared.recursiveChildrenIdResets(data.question.children);
                }

                //add a mention that this is a clone in the title text
                data.question.title.forEach(traduction => {
                    traduction.text = `${traduction.text} (clone)`;
                });
                commit('setIsLoading', false);
                return data.question;
            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },
        //cloning question
        async cloneQuestion({ commit, dispatch }, questionId) {
            commit('setLoadingMessage', 'Loading...');
            commit('setIsLoading', true);
            try {
                const questionClone = await dispatch('fetchQuestionClone', questionId);
                commit('setActiveQuestion', questionClone);
                commit('setIsLoading', false);
            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },
        async removeQuestionTag({ state, commit }, tag) {
            if (!!state.activeQuestion.id && !!tag.id) {
                const response = await $axios.delete(`questions/${state.activeQuestion.id}/tags/${tag.id}`);
            }
            commit('removeQuestionTag', tag);
        },

        //TAGS
        async fetchTags({ commit }) {
            commit('setLoadingMessage', 'Loading...');
            commit('setIsLoading', true);
            try {
                const { data } = await $axios.get('tags');
                commit('setTags', data.tags);
                commit('setIsLoading', false);
            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }

        },
        async storeTag({ commit }, tag) {
            commit('setLoadingMessage', 'Saving...');
            commit('setIsLoading', true);
            try {
                const { data } = await $axios.post('tags', tag);
                commit('addTag', data.tag);
                commit('setIsLoading', false);
            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },
        async editTag({ commit }, tag) {
            commit('setLoadingMessage', 'Saving...');
            commit('setIsLoading', true);
            try {
                const requestBody = {...tag};
                delete requestBody.id;

                const { data } = await $axios.put(`tags/${tag.id}`, requestBody);
                commit('modifyTag', data.tag);
                commit('setIsLoading', false);
            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },
        async destroyTag({ commit }, id) {
            commit('setLoadingMessage', 'Deleting...');
            commit('setIsLoading', true);
            try {
                const { data } = await $axios.delete(`tags/${id}`);
                commit('removeTag', data.tag);
                commit('setIsLoading', false);
            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },

        //CATEGORIES
        async fetchCategories({ commit }) {
            commit('setLoadingMessage', 'Loading...');
            commit('setIsLoading', true);
            try {
                const response = await $axios.get('categories/');
                commit('setCategories', response.data);
                commit('setIsLoading', false);
            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }

        },
        async storeCategory({ commit }, category) {
            commit('setLoadingMessage', 'Saving...');
            commit('setIsLoading', true);
            try {
                const response = await $axios.post('categories', category);
                commit('setCategories', response.data);
                commit('setIsLoading', false);
            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },
        async editCategory({ commit }, category) {
            commit('setLoadingMessage', 'Saving...');
            commit('setIsLoading', true);
            try {
                const response = await $axios.put(`categories/${category.id}`, category);
                commit('setCategories', response.data);
                commit('setIsLoading', false);
            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },
        async deleteCategory({ commit }, category) {
            commit('setLoadingMessage', 'Deleting...');
            commit('setIsLoading', true);
            try {
                const response = await $axios.delete(`categories/${category.id}`);
                commit('setCategories', response.data);
                commit('setIsLoading', false);
            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },

        //SURVEYS
        async fetchSurveys({ commit }) {
            commit('setLoadingMessage', 'Loading...');
            commit('setIsLoading', true);
            try {
                const response = await $axios.get('surveys/');
                commit('setSurveys', response.data.surveys);
                commit('setIsLoading', false);
            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },
        async fetchFullSurvey({ commit }, surveyId) {
            commit('setLoadingMessage', 'Loading...');
            commit('setIsLoading', true);
            try {
                const response = await $axios.get(`surveys/full/${surveyId}`);
                commit('commitActiveSurvey', response.data.survey);
                commit('setIsLoading', false);
            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },
        async storeSurvey({ commit }, survey) {
            commit('setLoadingMessage', 'Saving...');
            commit('setIsLoading', true);
            try {
                const response = await $axios.post('surveys/', survey);
                commit('addSurvey', response.data.survey);
                commit('setIsLoading', false);
            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },
        async editSurvey({ commit }, survey) {
            commit('setLoadingMessage', 'Saving...');
            commit('setIsLoading', true);
            try {
                const response = await $axios.put(`surveys/${survey.id}`, survey);
                commit('editSurvey', response.data.survey);
                commit('setIsLoading', false);
            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },
        async deleteSurvey({ commit }, survey) {
            commit('setLoadingMessage', 'Deleting...');
            commit('setIsLoading', true);
            try {
                const response = await $axios.delete(`surveys/${survey.id}`);
                commit('deleteSurvey', response.data);
                commit('setIsLoading', false);
            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },
        //cloning survey
        async cloneSurvey({ commit }, surveyId) {
            commit('setLoadingMessage', 'Loading...');
            commit('setIsLoading', true);
            try {
                const response = await $axios.get(`surveys/full/${surveyId}`);
                //deleting unwanted datas from the original survey
                delete response.data.survey.id;
                delete response.data.survey.assignations;
                for(let section of response.data.survey.sections) {
                    delete section.id;

                    for(let question of section.questions) {
                        delete question.id;
                    }
                }

                commit('commitActiveSurvey', response.data.survey);
                commit('setIsLoading', false);
            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },

        async addSurveyAssignation({ commit }, args) {
            commit('setLoadingMessage', 'Saving...');
            commit('setIsLoading', true);
            try {
                const { data } = await $axios.post(`surveys/assignation/${args.survey.id}`, args);
                commit('addAssignationActiveSurvey', data.assignation);
                commit('setIsLoading', false);
                return data.assignation.hash;
            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },
        async editSurveyAssignation({ commit }, assignation) {
            commit('setLoadingMessage', 'Saving...');
            commit('setIsLoading', true);
            try {
                const response = await $axios.put(`surveys/assignation/${assignation.id}`, assignation);
                commit('editAssignationActiveSurvey', response.data.assignation);
                commit('setIsLoading', false);
            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },
        //deletes assignation in IndexQuestionnaire.vue
        async deleteSurveyAssignation({ commit }, assignation) {
            commit('setLoadingMessage', 'Deleting...');
            commit('setIsLoading', true);
            try {
                const response = await $axios.delete(`surveys/assignation/${assignation.id}`);
                commit('deleteAssignationActiveSurvey', response.data);
                commit('setIsLoading', false);
            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },
        //send reminder for assignation of a survey (use in IndexSurvey.vue)
        async sendSurveyAssignationReminder({ commit }, assignation) {
            commit('setLoadingMessage', 'Saving...');
            commit('setIsLoading', true);
            try {
                const { data } = await $axios.post(`surveys/assignation/reminder/${assignation.id}`);
                commit('modifyAssignationStateActiveSurvey', data.assignation);
                commit('setIsLoading', false);
            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },

        //ASSIGNATIONS
        //sends email reminder (use in IndexAssignations.vue)
        async sendAssignationReminder({ commit }, assignation) {
            commit('setLoadingMessage', 'Saving...');
            commit('setIsLoading', true);
            try {
                const { data } = await $axios.post(`surveys/assignation/reminder/${assignation.id}`);
                commit('modifyAssignationState', data.assignation);
                commit('setIsLoading', false);

                //we return the assignation so we can show a pop up message it was successful
                return data.assignation;
            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },
        //deletes assignation in IndexAssignations.vue
        async deleteAssignation({ commit }, assignation) {
            commit('setLoadingMessage', 'Deleting...');
            commit('setIsLoading', true);
            try {
                const response = await $axios.delete(`surveys/assignation/${assignation.id}`);
                commit('removeAssignation', response.data);
                commit('setIsLoading', false);
            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },
        //change the expiration date of an assignation
        async saveAssignationExpirationDate({commit}, assignation) {
            commit('setLoadingMessage', 'Saving...');
            commit('setIsLoading', true);
            try {
                const { data } = await $axios.post(`surveys/assignation/set-expiration/${assignation.id}`, assignation);
                commit('setActiveAssignation', data.assignation);
                commit('setIsLoading', false);
            }
            catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
         },
        //Change is_survey_stamp to True
        async saveAssignationSurveyStamp({commit}, assignation) {
            commit('setLoadingMessage', 'Saving...');
            commit('setIsLoading', true);
            try {
                const { data } = await $axios.post(`surveys/assignation/set-stamp/${assignation.id}`, assignation);
                commit('setActiveAssignation', data.assignation);
                commit('setIsLoading', false);
            }
            catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },
        //fetch assignation with hash
        async fetchAssignation({ commit }, hash) {
            commit('setLoadingMessage', 'Loading...');
            commit('setIsLoading', true);
            try {
                const { data } = await $axios.get(`surveys/assignation/${hash}`);
                commit('setActiveAssignation', data.assignation);
                //preloading survey type
                commit('setSurveyType', data.surveyType);
                //preloading all stage units
                commit('setAllStageUnits', data.stageUnitsList);
                commit('setIsLoading', false);
            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },
        //fetch assignation with client hash
        async fetchInspectionReportAssignation({ commit }, hash) {
            commit('setLoadingMessage', 'Loading...');
            commit('setIsLoading', true);
            try {
                const { data } = await $axios.get(`surveys/assignation/report/${hash}`);
                commit('setActiveAssignation', data.assignation);
                commit('setIsLoading', false);
            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },
        //fetch assignation with id
        async fetchAssignationById({ commit }, id) {
            commit('setLoadingMessage', 'Loading...');
            commit('setIsLoading', true);
            try {
                const { data } = await $axios.get(`surveys/assignation/by-id/${id}`);
                commit('setActiveAssignation', data.assignation);
                //preloading all stage units
                commit('setAllStageUnits', data.stageUnitsList);
                commit('setIsLoading', false);
            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },
        //fetch assignation with user id
        async fetchAssignationsByUserId({ commit }, id) {
            commit('setLoadingMessage', 'Loading...');
            commit('setIsLoading', true);
            try {
                const { data } = await $axios.get(`surveys/assignation/user/${id}`);
                commit('setAllAssignations', data.assignations);
                commit('setIsLoading', false);
            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },
        //fetch all assignations for assignations view
        async fetchAllAssignations({ commit }) {
            commit('setLoadingMessage', 'Loading...');
            commit('setIsLoading', true);
            try {
                const { data } = await $axios.get('surveys/assignation/all');
                commit('setAllAssignations', data.assignations);
                commit('setIsLoading', false);
            } catch(error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },
        //send reminder for assignation of a survey (use in IndexSurvey.vue)
        async processAssignation({ commit }, assignation) {
            commit('setLoadingMessage', 'Saving...');
            commit('setIsLoading', true);
            try {
                const { data } = await $axios.post(`surveys/assignation/process/${assignation.id}`);
                commit('setActiveAssignation', data.assignation);
                commit('setIsLoading', false);
            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },
        async setAssignationLang({ commit }, assignation) {
            commit('setLoadingMessage', 'Saving...');
            commit('setIsLoading', true);
            try {
                const { data } = await $axios.put(`surveys/assignation/edit-lang/${assignation.id}`, {
                    lang: assignation.defaultLang
                });
                commit('setIsLoading', false);
                return data.success;
            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },

        //CLIENT REPORT
        //fetch assignation with client hash
        async fetchClientInspectionReportAssignation({ commit }, hash) {
            commit('setLoadingMessage', 'Loading...');
            commit('setIsLoading', true);
            try {
                const { data } = await $axios.get(`surveys/assignation/client-report/${hash}`);
                commit('setClientAssignationInfo', data.assignationInfo);
                commit('setResolutions', data.resolutions);
                commit('setResolutionsParts', data.maintenanceProjectParts);
                commit('setIsLoading', false);
            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },

        //ANSWERS
        async postAnswers({ commit }, args) {
            commit('setLoadingMessage', 'Saving...');
            commit('setIsLoading', true);
            try {
                const requestBody = {...args};
                delete requestBody.hash;

                const { data } = await $axios.post(`surveys/answers/${args.hash}`, requestBody);
                //reaffecting the same assignation to get the current state
                !!data.assignation && commit('setActiveAssignation', data.assignation);
                commit('setIsLoading', false);
            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },

        async postSingleAnswer({ commit }, {answer, hash}) {
            commit('setLoadingMessage', 'Saving...');
            commit('setIsLoading', true);
            try {
                const requestBody = {...answer};

                const { data } = await $axios.post(`surveys/assignation/${hash}/single-answer`, requestBody);
                commit('setIsLoading', false);

            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },

        //STAGE MODELS
        async fetchAllStageModels({ commit }) {
            commit('setLoadingMessage', 'Loading...');
            commit('setIsLoading', true);
            try {
                const { data } = await $axios.get('stages/all');
                commit('setAllStageModels', data.data);
                commit('setIsLoading', false);
            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },

        //STAGE UNITS
        async fetchAllStageUnits({ commit }) {
            commit('setLoadingMessage', 'Loading...');
            commit('setIsLoading', true);
            try {
                const { data } = await $axios.get('units');
                commit('setAllStageUnits', data.units);
                commit('setIsLoading', false);
            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },

        //SUPPLIERS
        async fetchSuppliers({ commit }, args) {
            commit('setLoadingMessage', 'Loading...');
            commit('setIsLoading', true);
            try {
                //the query must contains at least 3 characters before starting a search
                if(!!args && args.search.length >= 3) {
                    const { data } = await $axios.get('suppliers', { params: { includeTrashed: args.includeTrashed, search: args.search } });
                    args.flag === 'clientSupplier' && commit('setClientSuppliers', data.data);
                    args.flag === 'assignedSupplierContact' && commit('setSuppliers', data.data);
                }
                commit('setIsLoading', false);
            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },

        //SURVEY TYPES
        async fetchSurveyTypes({commit}) {
            commit('setLoadingMessage', 'Loading...');
            commit('setIsLoading', true);
            try {
                const response = await $axios.get(`surveys/type`);
                commit('setSurveyTypes', response.data.types);
                commit('setIsLoading', false);
            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },
        async storeSurveyType({commit}, surveyType) {
            commit('setLoadingMessage', 'Saving...');
            commit('setIsLoading', true);
            try {
                const response = !!surveyType.id ? await $axios.put(`surveys/type/${surveyType.id}`, surveyType) : await $axios.post(`surveys/type`, surveyType);
                if (!surveyType.id) {
                    commit('addSurveyType', response.data.type);
                }
                else {
                    commit('commitActiveSurveyType', response.data.type)
                }
                commit('setIsLoading', false);
            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },
        async deleteSurveyType({commit}, surveyType) {
            commit('setLoadingMessage', 'Deleting...');
            commit('setIsLoading', true);
            try {
                const response = await $axios.delete(`surveys/type/${surveyType.id}`, surveyType);
                commit('deleteSurveyType', response.data.type);
                commit('setIsLoading', false);
            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },

        //NC
        async fetchDepartments({ commit }) {
            commit('setLoadingMessage', 'Loading...');
            commit('setIsLoading', true);
            try {
                const { data } = await $axios.get('nc/departements/list');
                commit('setNcDepartments', data.departements);
                commit('setNcUnderDepartments', data.departements);
                commit('setIsLoading', false);
            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },

        //fetches NC categories, causes and states (I hate how the controller of this route is done but it was already like that!)
        async fetchParamsList({ commit }) {
            commit('setLoadingMessage', 'Loading...');
            commit('setIsLoading', true);
            try {
                const { data } = await $axios.get('nc/params/list');
                //categories
                commit('setNcCategories', data.categories);
                //causes
                commit('setNcCauses', data.causes);
                //states
                commit('setNcStates', data.etats);
                commit('setIsLoading', false);
            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },

        //fetches correctives actions by type (qualite, maintenance or sav)
        async fetchCorrectivesActionsByType({ commit }, type) {
            commit('setLoadingMessage', 'Loading...');
            commit('setIsLoading', true);
            try {
                const { data } = await $axios.get(`nc/correctives/${type}`);
                commit('setNcCorrectivesActions', data.correctivesActions);
                commit('setIsLoading', false);
            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },

        async storeNC({ commit }, nc) {
            commit('setLoadingMessage', 'Saving...');
            commit('setIsLoading', true);
            try {
                const { data } = await $axios.post('nc', nc);
                commit('setIsLoading', false);

                return data.nonConformite;
            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },

        //fetches Syteline
        fetchLastProjectNumberFromSyteline({state, commit}, fromApp) {
            commit('isLoading', true);
            return new Promise( (resolve, reject) => {
                $axios.get(`/erp/last-project-number/${fromApp}`).then(response => {
                    resolve(response);
                }).catch(error => {
                    commit('isLoading', false);
                    reject(error);
                })
            })
        },

        fetchAddNewProjectToSyteline({state, commit}, data) {
            return new Promise( (resolve, reject) => {
                let encode = JSON.stringify(data);
                $axios.post(`/erp/add-project/${encode}`).then(response => {
                    commit('isLoading', false);
                    resolve(response);
                }).catch(error => {
                    commit('isLoading', false);
                    reject(error);
                })
            })
        },

        fetchUpdateProjectDescriptionToSyteline({state, commit}, data) {
            commit('isLoading', true);
            return new Promise( (resolve, reject) => {
                let encode = JSON.stringify(data);
                $axios.post(`/erp/update-project-description/${encode}`).then(response => {
                    commit('isLoading', false);
                    resolve(response);
                }).catch(error => {
                    commit('isLoading', false);
                    reject(error);
                })
            })
        },

        fetchAddNewProjectNumberToNonConformite({state, commit}, data) {
            return new Promise( (resolve, reject) => {
                $axios.post(`/nc/${data.Id}/maintenance/syteline-project-number/${data.Projet}/`).then(response => {
                    resolve(response);
                }).catch(error => {
                    commit('isLoading', false);
                    reject(error);
                })
            })
        },

        //uploads NC client image
        async uploadClientImage({ commit }, { clientImages, nonConformiteId }) {
            commit('setLoadingMessage', 'Saving...');
            commit('setIsLoading', true);
            try {
                const { data } = await $axios.post(`/nc/${nonConformiteId}/uploadImages`, clientImages);
                commit('setIsLoading', false);

                return data.nonConformite;
            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },
        //uploads NC image
        async uploadNCImage({ commit }, { images, nonConformiteId }) {
            commit('setLoadingMessage', 'Saving...');
            commit('setIsLoading', true);
            try {
                const { data } = await $axios.post(`/nc/${nonConformiteId}/uploadImages`, images);
                commit('setIsLoading', false);

                return data.nonConformite;
            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },

        //deletes client image
        async deleteClientImage({ commit }, { clientImageTitle, imageOrigin, nonConformiteId }) {
            commit('setLoadingMessage', 'Deleting...');
            commit('setIsLoading', true);
            try {
                const { data } = await $axios.delete(`/nc/${nonConformiteId}/deleteImage/${imageOrigin}/${clientImageTitle}`);
                commit('setIsLoading', false);

                return data.nc;
            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },

        //uploads NC client file
        async uploadClientFile({ commit }, { clientFiles, nonConformiteId }) {
            commit('setLoadingMessage', 'Saving...');
            commit('setIsLoading', true);
            try {
                const { data } = await $axios.post(`/nc/${nonConformiteId}/uploadFichiers`, clientFiles);
                commit('setIsLoading', false);

                return data.nonConformite;
            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },

        //deletes client file
        async deleteClientFile({ commit }, { clientFileName, fileOrigin, nonConformiteId }) {
            commit('setLoadingMessage', 'Deleting...');
            commit('setIsLoading', true);
            try {
                const { data } = await $axios.delete(`/nc/${nonConformiteId}/deleteFichier/${fileOrigin}/${clientFileName}`);
                commit('setIsLoading', false);

                return data.nc;
            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },

        //Save rotated NC image
        async saveRotatedImage({ commit }, params) {
            commit('setLoadingMessage', 'Saving...');
            commit('setIsLoading', true);
            try {
                const { data } = await $axios.post(`/nc/saveRotatedImage`, params);
                commit('setIsLoading', false);

                return data;
            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },

        //Maintenance dashboard actions
        async fetchMaintenanceNCList({ commit }, { includeCompleted, includeDeleted }) {
            commit('setLoadingMessage', 'Loading...');
            commit('setIsLoading', true);

            try {
                const { data } = await $axios.get('nc/maintenance-gallup', { params: { includeCompleted, includeDeleted } });
                //categories
                commit('setNcList', data.ncList);
                commit('setIsLoading', false);
            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },

        async fetchMaintenanceNC({ commit, dispatch }, { nonConformiteId }) {
            commit('setLoadingMessage', 'Loading...');
            commit('setIsLoading', true);
            try {
                const { data } = await $axios.get(`/nc/${nonConformiteId}`);
                commit('setActiveMaintenanceNC', data.nonConformite);
                commit('setActiveNCGallupInfos', data.gallupInfos);
                commit('setIsLoading', false);
            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },

        async saveMaintenanceProjectSelectedTasks({ commit }, {nonConformiteId, tasks}) {
            commit('setLoadingMessage', 'Saving...');
            commit('setIsLoading', true);
            try {
                const { data } = await $axios.post(`/nc/${nonConformiteId}/maintenance/tasks/`, { tasks: tasks });
                commit('setActiveMaintenanceNC', data.nonConformite);
                commit('setIsLoading', false);

            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },

        async saveMaintenanceProjectTaskStatus({ commit }, {nonConformiteId, taskName}) {
            commit('setLoadingMessage', 'Saving...');
            commit('setIsLoading', true);
            try {
                const { data } = await $axios.post(`/nc/${nonConformiteId}/maintenance/taskStatus/`, { taskName: taskName });
                //commit('setActiveMaintenanceNC', data.nonConformite);
                commit('setIsLoading', false);
                return data.newTaskStatus;
            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },

        async saveMaintenanceProjectEnjeuLocation({ commit }, { nonConformiteId, checked }){
            commit('setLoadingMessage', 'Saving...');
            commit('setIsLoading', true);
            try {
                const { data } = await $axios.post(`/nc/${nonConformiteId}/maintenance/enjeuLocation/`, { nonConformiteId, checked });
                commit('setActiveMaintenanceNC', data.nonConformite);
                commit('setIsLoading', false);
            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },

        async saveMaintenanceProject({ commit }, nonConformite) {
            commit('setLoadingMessage', 'Saving...');
            commit('setIsLoading', true);
            try {
                const { data } = await $axios.post(`/nc/${nonConformite.id}/maintenance/project/`, { nonConformite: nonConformite });
                commit('setActiveMaintenanceNC', data.nonConformite);
                commit('commitActiveMaintenanceNC');
                commit('setIsLoading', false);
            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },

        async validateSMARTProjectNumber({state}, projectNumber) {
            const validProject = await $axios.get(`/nc/validate-project/${projectNumber}`);
            return validProject.data.valid;
        },

        async fetchMaintenanceStagesReport({commit}) {
            commit('setLoadingMessage', 'Saving...');
            commit('setIsLoading', true);
            try {
                const { data } = await $axios.get('/nc/maintenance-stages-report/');
                commit('setMaintenanceStagesReport', data.stages);
                commit('setIsLoading', false);
            } catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }

        },

        fetchDataProjectNumber({state}, data) {
            return new Promise( (resolve, reject) => {
                $axios.get(`/sigm/project/${data.projectNumber}`).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                })
            })
        },

        async fetchPartNos({commit}) {
            const partNos = await $axios.get(`/sigm/part-nos`);
            commit('setDataPartNos', partNos.data)
        },

        async fetchNcReport({commit}, hash) {
            commit('setIsLoading', true);
            try {
                const response = await $axios.get(`/nc/rapports/nc-report/${hash}`);
                commit('setNcReport', response.data);
                commit('setIsLoading', false);
            }
            catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },

        async fetchNcReportsIndex({commit}, hash) {
            commit('setIsLoading', true);
            try {
                const response = await $axios.get(`/nc/rapports/nc-report-index`);
                commit('setNcReportsIndex', response.data);
                commit('setIsLoading', false);
            }
            catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },

        async saveNcReport({commit}, {ncIds, project, qualityAgentId}) {
            commit('setIsLoading', true);
            try {
                const response = await $axios.post(`/nc/rapports/nc-report`, {ncIds: ncIds, project: project, qualityAgentId: qualityAgentId});
                commit('setIsLoading', false);
                return response;
            }
            catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },

        async editNcReport({commit}, {ncIds, reportId, qualityAgentId}) {
            commit('setIsLoading', true);
            try {
                const response = await $axios.put(`/nc/rapports/nc-report`, {ncIds: ncIds, reportId: reportId, qualityAgentId: qualityAgentId});
                commit('updateReportInIndex', response.data.report);
                commit('setIsLoading', false);
                return response.data;
            }
            catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },

        async deleteNcReport({commit}, report) {
            commit('setIsLoading', true);
            try {
                const response = await $axios.delete(`/nc/rapports/nc-report/${report.id}`);
                commit('deleteReportFromIndex', response.data.report);
                commit('setIsLoading', false);
            }
            catch (error) {
                commit('setErrorMessage', error);
                commit('setIsErrorState', true);
            }
        },

        //Dev Function *KB*
        addMessageToDevBuilder({state}, text){
            state.devMessages.push(text);
        }
    }
})
