import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n';
import ImageUploaderPlugin from "vue-image-upload-resize";
import vueSignature from 'vue-signature';
import './assets/css/gallup_style.css';
import VueQriously from 'vue-qriously';

// import plugin tiptap
import {TiptapVuetifyPlugin} from 'tiptap-vuetify'
// don't forget to import CSS styles
import 'tiptap-vuetify/dist/main.css'

Vue.use(VueQriously);

// use this package's plugin
Vue.use(TiptapVuetifyPlugin, {
    // the next line is important! You need to provide the Vuetify Object to this place.
    vuetify, // same as "vuetify: vuetify"
    // optional, default to 'md' (default vuetify icons before v2.0.0)
    //iconsGroup: 'md'
});

Vue.use(ImageUploaderPlugin);
Vue.use(vueSignature);

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App)
}).$mount('#app');
